<template>
    <el-main>
        <el-tabs v-model="activeName">
            <el-tab-pane label="基本信息" name="first"></el-tab-pane>
            <el-tab-pane label="商品规格" name="second"></el-tab-pane>
        </el-tabs>
        <el-form :model="goodsForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <div v-if="activeName == 'first'">
                <el-form-item label="排序：" prop="sort">
                    <el-input v-model="goodsForm.sort" type="number"></el-input>
                </el-form-item>
                <el-form-item label="商品分类：" prop="cats">
                    <el-cascader v-model="cate_value" :props="cate_props"></el-cascader>
                </el-form-item>
                <el-form-item label="商品名称：" prop="title">
                    <el-input v-model="goodsForm.title"></el-input>
                </el-form-item>
                <el-form-item label="商品副标题：">
                    <el-input v-model="goodsForm.sub_title"></el-input>
                </el-form-item>
                <el-form-item label="商品主图：" prop="head_imgs">
                    <ReadyUploadSource @getSource="(data) => data.map((item) => goodsForm.head_imgs.push(item.path))"
                        @changeOrder="(data) => (goodsForm.head_imgs = data)"
                        @removeThis="(i) => goodsForm.head_imgs.splice(i, 1)" :isMany="true" :manyPath="goodsForm.head_imgs"
                        :isManyMax="9" :maxSize="1024 * 1024">
                    </ReadyUploadSource>
                    <p class="tips">
                        建议图片尺寸750px*750px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）
                    </p>
                </el-form-item>
                <el-form-item label="商品详图：" prop="desc_info">
                    <ReadyUploadSource @getSource="(data) => data.map((item) => goodsForm.desc_info.imgs.push(item.path))
                        " @changeOrder="(data) => (goodsForm.desc_info.imgs = data)"
                        @removeThis="(i) => goodsForm.desc_info.imgs.splice(i, 1)" :isMany="true"
                        :manyPath="goodsForm.desc_info.imgs" :isManyMax="20" :maxSize="1024 * 1024">
                    </ReadyUploadSource>
                    <p class="tips">大小不超过1M</p>
                </el-form-item>
                <el-form-item label="商品属性：" prop="attrs">
                    <div v-for="(item, index) in goodsForm.attrs" :key="index" class="attrsBox">
                        <el-input v-model="item.attr_value" placeholder="属性名"></el-input>
                        <el-input v-model="item.attr_key" placeholder="属性值"></el-input>
                        <el-image v-if="goodsForm.attrs.length > 1" class="deleteAttrs"
                            @click="goodsForm.attrs.splice(index, 1)"
                            src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"></el-image>
                    </div>
                    <el-button type="text" @click="goodsForm.attrs.push({ attr_key: '', attr_value: '' })">+
                        添加属性</el-button>
                </el-form-item>
            </div>
            <div v-if="activeName == 'second'">
                <el-form-item label="商品规格：">
                    <el-table :data="goodsForm.skus" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
                        <el-table-column align="center">
                            <template #header>
                                <span style="color: red">*</span>
                                <span>SKU属性</span>
                            </template>
                            <template v-slot="{ row }">
                                <el-input v-if="!g_id" size="small" v-model="row.sku_attrs[0].attr_key"></el-input>
                                <div v-else>
                                    <div v-if="!row.is_edit">{{ row.sku_attrs[0].attr_key }}</div>
                                    <el-input v-else size="small" v-model="row.sku_attrs[0].attr_key"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template #header>
                                <span style="color: red">*</span>
                                <span>SKU属性值</span>
                            </template>
                            <template v-slot="{ row }">
                                <el-input v-if="!g_id" size="small" v-model="row.sku_attrs[0].attr_value"></el-input>
                                <div v-else>
                                    <div v-if="!row.is_edit">{{ row.sku_attrs[0].attr_value }}</div>
                                    <el-input v-else size="small" v-model="row.sku_attrs[0].attr_value"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template #header>
                                <span style="color: red">*</span>
                                <span>售卖价格</span>
                            </template>
                            <template v-slot="{ row }">
                                <el-input v-if="!g_id" size="small" v-model="row.sale_price" type="number"></el-input>
                                <div v-else>
                                    <div v-if="!row.is_edit">{{ row.sale_price }}</div>
                                    <el-input type="number" v-else size="small" v-model="row.sale_price"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template #header>
                                <span style="color: red">*</span>
                                <span>SKU库存</span>
                            </template>
                            <template v-slot="{ row }">
                                <el-input size="small" v-if="!g_id" v-model="row.stock_num" type="number"></el-input>
                                <div v-else>
                                    <div v-if="!row.is_edit">{{ row.stock_num }}</div>
                                    <el-input type="number" v-else size="small" v-model="row.stock_num"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template #header>
                                <span>商品图片(800*800px)</span>
                            </template>
                            <template v-slot="{ row }">
                                <div class="spec_img" v-if="!g_id">
                                    <ReadyUploadSource :showStyle="{
                                        width: '70px',
                                        height: '70px',
                                    }" type="image" :path="row.thumb_img" @removeThis="() => (row.thumb_img = '')"
                                        @getSource="(val) => (row.thumb_img = val.path)"></ReadyUploadSource>
                                </div>
                                <div v-else class="spec_img">
                                    <ReadyUploadSource :showStyle="{
                                        width: '70px',
                                        height: '70px',
                                    }" type="image" :path="row.thumb_img" @removeThis="() => (row.thumb_img = '')"
                                        @getSource="(val) => (row.thumb_img = val.path)" v-if="row.is_edit">
                                    </ReadyUploadSource>
                                    <el-image v-if="row.thumb_img && !row.is_edit" style="width: 70px; height: 70px"
                                        :src="row.thumb_img"></el-image>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="操作">
                            <template v-slot="{ row, $index }">
                                <el-button v-if="goodsForm.skus.length > 1" type="text"
                                    @click="deleteSku(row, $index)">删除</el-button>
                                <el-button type="text" v-if="!row.is_edit" @click="startEditSku(row)">编辑</el-button>
                                <el-button type="text" v-if="row.is_edit" @click="sureAddSku(row)">完成</el-button>
                                <el-button type="text" v-if="row.is_edit" @click="cancleEdit(row, $index)">取消</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-button type="text" @click="addSpecFn">+ 添加规格</el-button>
                </el-form-item>
                <el-form-item label="是否需要快递：" prop="deliver_method">
                    <el-radio-group v-model="goodsForm.deliver_method">
                        <el-radio :label="0">快递发货</el-radio>
                        <el-radio :label="1">无需快递</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="选择运费模板：" v-if="!goodsForm.deliver_method">
                    <el-select v-more="loadMoreType" v-model="goodsForm.express_info.template_id" filterable remote
                        :remote-method="getFreightList" placeholder="请选择" class="classify_select" @change="fn">
                        <el-option v-for="item in freight_list" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <Preservation @preservation="goodsSubmit"></Preservation>
    </el-main>
</template>

<script>
import ReadyUploadSource from "@/components/readyUploadSource";
import Preservation from "@/components/preservation";
import _ from "lodash";
export default {
    components: {
        ReadyUploadSource,
        Preservation,
    },
    data () {
        let that = this;
        function checkHeadImgs (rule, value, callback) {
            if (!value.length) {
                callback(new Error("请添加商品主图"));
            } else {
                callback();
            }
        }
        function checkCate (rule, value, callback) {
            if (value && !value.length) {
                callback(new Error("请选择商品分类"));
            } else {
                callback();
            }
        }
        function checkImgs (rule, value, callback) {
            console.log(value)
            if (!value.imgs.length) {
                callback(new Error("请添加商品详情图"));
            } else {
                callback();
            }
        }
        function checkAttrs (rule, value, callback) {
            let flag = true;
            for (let i = 0; i < that.goodsForm.attrs.length; i++) {
                let data = that.goodsForm.attrs[i];
                if (!data.attr_key || !data.attr_value) {
                    flag = false;
                    break;
                }
            }
            if (!flag) {
                callback(new Error("请将数据填写完整"));
            } else {
                callback();
            }
        }
        return {
            g_id: null,
            // 企业信息
            c_info: {
                id: null,
                c_name: "",
                name: "",
            },
            activeName: "first",
            cate_props: {
                lazy: true,
                label: "name",
                value: "id",
                lazyLoad (node, resolve) {
                    const { level, data } = node;
                    let id = 0;
                    if (level) id = data.id;
                    that.getCateList(resolve, level, id);
                },
            },
            cate_value: [],
            // 添加的商品数据
            goodsForm: {
                sort: 99,
                // 选中的分类
                cats: [{
                    cat_id: '',
                }, {
                    cat_id: '',
                }, {
                    cat_id: '',
                }],
                // 商品标题
                title: "",
                // 商品副标题
                sub_title: "",
                // 商品主图
                head_imgs: [],
                // 商品详细信息
                desc_info: {
                    // 商品详情图
                    imgs: [],
                    // 商品详情文本
                    desc: "物美价廉",
                },
                // 商品参数
                attrs: [
                    {
                        // 属性键key
                        attr_key: "",
                        // 属性值value
                        attr_value: "",
                    },
                ],
                // 发货方式：0快递发货；1无需快递
                deliver_method: 0,
                // 运费信息
                express_info: [
                    {
                        // 运费模板ID
                        template_id: 0,
                        // 商品重量
                        weight: "",
                    },
                ],
                skus: [
                    {
                        sku_attrs: [
                            {
                                attr_key: "",
                                attr_value: "",
                            },
                        ],
                        sale_price: "",
                        //market_price: '',
                        stock_num: "",
                        thumb_img: "",
                        // 是否已经保存
                        is_save: false,
                        // 是否开启编辑
                        is_edit: false,
                    },
                ],
            },
            // 运费列表的查询配置
            freight_config: {
                name: "",
                page: 1,
                rows: 15,
                total: 0,
            },
            // 运费列表
            freight_list: [],
            rules: {
                cats: [{ required: true, validator: checkCate, trigger: "change" }],
                title: [{ required: true, message: "请填写商品名称", trigger: "blur" }],
                sort: [{ required: true, message: "请填写排序", trigger: "blur" }],
                head_imgs: [{ required: true, validator: checkHeadImgs, trigger: "change" }],
                imgs: [{ required: true, validator: checkImgs, trigger: "change" }],
                attrs: [{ required: true, validator: checkAttrs, trigger: "change" }],
                deliver_method: [{ required: true, message: "", trigger: "blur" }],
            },
            // 临时存储规格数据
            temporarySku: {},
        };
    },
    created () {
        this.c_info.id = Number(this.$route.query.c_id);
        this.c_info.name = this.$route.query.name;
        this.c_info.c_name = this.$route.query.c_name;
        if (this.$route.query.g_id) {
            this.g_id = this.$route.query.g_id;
            this.getGoodsInfo();
        }
        this.getFreightList();
    },
    methods: {
        // 获取视频号商品分类
        getCateList (resolve, level, id) {
            let obj = {
                level: level + 1,
            };
            if (id) obj.id = id;
            this.$axios.post(this.$api.serviceProvider.catsList, obj).then((res) => {
                if (res.code == 0) {
                    Array.from(res.result).map((item) => {
                        if (level == 0) {
                            item.name = item.first_cat_name;
                            item.id = item.first_cat_id;
                        }
                        if (level == 1) {
                            item.name = item.second_cat_name;
                            item.id = item.second_cat_id;
                        }
                        if (level == 2) {
                            item.name = item.third_cat_name;
                            item.id = item.third_cat_id;
                            item.leaf = true;
                        }
                    });
                    resolve(res.result);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 点击添加新的规格
        addSpecFn () {
            if (this.g_id) {
                let errData = this.goodsForm.skus.find((item) => !item.is_save || item.is_edit);
                if (errData) {
                    this.$message.warning("请先将规格数据保存完整");
                    return;
                }
            }
            this.goodsForm.skus.push({
                sku_attrs: [
                    {
                        attr_key: "",
                        attr_value: "",
                    },
                ],
                sale_price: "",
                // market_price:'',
                is_save: false,
                is_edit: true,
                stock_num: "",
                thumb_img: "",
            });
        },
        // 删除选中的规格
        deleteSku (data, index) {
            if (!data.is_save) {
                this.goodsForm.skus.splice(index, 1)
                return
            }
            this.$axios.post(this.$api.serviceProvider.delSku, {
                id: data.id
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('删除成功')
                    this.goodsForm.skus.splice(index, 1)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 服务分类下拉框 滑动加载更多数据
        loadMoreType () {
            if (
                Math.ceil(this.freight_config.total / this.freight_config.rows) >
                this.freight_config.page
            ) {
                this.freight_config.page++;
                this.getFreightList(this.freight_config.name, 1);
            }
        },
        // 获取运费列表
        getFreightList (name, is_more) {
            if (!is_more) {
                this.serviceClassifyPage = 1;
            }
            this.freight_config.name = name;
            this.$axios
                .post(this.$api.set.freightList, {
                    page: this.freight_config.page,
                    rows: this.freight_config.rows,
                    classify_name: name,
                })
                .then((res) => {
                    if (res.code === 0) {
                        if (is_more) {
                            this.freight_list = this.freight_list.concat(res.result.list);
                        } else {
                            this.freight_list = res.result.list;
                        }
                        this.freight_config.total = res.result.total_number;
                    }
                });
        },
        // 点击添加商品
        goodsSubmit () {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let obj = _.cloneDeep(this.goodsForm);
                    let path = this.$api.serviceProvider.addGoods
                    obj.company_id = this.c_info.id;
                    let arr = this.cate_value.map(item => {
                        return { cat_id: item }
                    })
                    obj.cats = arr
                    if (this.g_id) {
                        path = this.$api.serviceProvider.editGoods
                        obj.id = this.g_id
                        delete obj.skus
                    }
                    this.$axios.post(path, obj).then((res) => {
                        if (res.code == 0) {
                            let str = this.g_id ? '编辑商品成功' : "添加商品成功"
                            this.$message.success(str);
                            this.$router.push(
                                `/extension/gatherTweeters/caseGoodsList?c_name=${this.c_info.c_name}&c_id=${this.c_info.id}&name=${this.c_info.name}`
                            );
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
        // 查看商品详情
        getGoodsInfo () {
            this.$axios
                .post(this.$api.serviceProvider.companyGoodsInfo, {
                    id: this.g_id,
                })
                .then((res) => {
                    if (res.code === 0) {
                        res.result.attrs = JSON.parse(res.result.attrs);
                        res.result.cats = Array.from(
                            JSON.parse(res.result.cats),
                            (item) => (item = { cat_id: item.cat_id ? Number(item.cat_id) : Number(item) })
                        );
                        this.cate_value = res.result.cats.map(item => item.cat_id)
                        res.result.desc_info = JSON.parse(res.result.desc_info);
                        res.result.express_info = res.result.express_info ? JSON.parse(res.result.express_info) : { template_id: 0 };
                        res.result.head_imgs = JSON.parse(res.result.head_imgs);
                        res.result.skus.map((item) => {
                            item.sku_attrs = JSON.parse(item.sku_attrs);
                            this.$set(item, "is_save", true);
                            this.$set(item, "is_edit", false);
                        });
                        res.result.express_info.template_id =
                            Number(res.result.express_info.template_id) || null;
                        this.goodsForm = res.result;
                        console.log(this.goodsForm)
                        console.log(this.cate_value)

                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        // 点击开启编辑规格
        startEditSku (data) {
            // 缓存该数据
            this.temporarySku = _.cloneDeep(data);
            // 开启编辑模式
            data.is_edit = true;
        },
        // 取消编辑规格
        cancleEdit (data, $index) {
            if (!data.is_save) {
                this.goodsForm.skus.splice($index, 1);
                return;
            }
            // 关闭编辑模式
            data.is_edit = false;
            // 回写数据
            data = _.cloneDeep(this.temporarySku);
            // 清除缓存
            this.temporarySku = {};
        },
        // 确认添加该规格
        sureAddSku (data) {
            if (!data.is_save) this.operationSku(data)
            if (data.is_save && data.is_edit && data.id) this.operationSku(data, 1)
        },
        // 编辑时操作规格
        operationSku (data, status) {
            // 默认执行添加操作 传status执行编辑操作
            if (
                data.sku_attrs[0].attr_value === "" ||
                data.sku_attrs[0].attr_key === "" ||
                data.stock_num === "" ||
                data.sale_price === ""
            ) {
                this.$message.warning("请将必填数据填写完整");
                return;
            }
            let path = this.$api.serviceProvider.addSku;
            let obj = _.cloneDeep(data);
            delete obj.is_save;
            delete obj.is_edit;
            obj.goods_id = this.g_id
            obj.market_price = "111";
            if (status) {
                path = this.$api.serviceProvider.editSku;
            }
            this.$axios.post(path, obj).then((res) => {
                if (res.code == 0) {
                    // 操作成功
                    if (!status) {
                        this.$set(data, 'is_save', true)
                        this.$set(data, 'id', res.result)
                    }
                    this.$set(data, 'is_edit', false)
                } else {
                    this.$message.error(res.msg)
                };
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    min-height: 100%;
    background-color: #fff;
    padding-bottom: 100px;

    .tips {
        color: #999;
    }

    .el-input {
        width: 400px;
    }

    .attrsBox {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-top: 10px;
        }

        .el-input {
            &:not(:last-child) {
                margin-right: 20px;
            }

            width: 190px;
        }

        .deleteAttrs {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .el-table {
        .el-input {
            max-width: 150px;
        }

        .spec_img {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            height: 80px;
        }
    }
}
</style>
